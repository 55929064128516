import React from 'react';
import { useIntl } from 'react-intl';
import { Dialog } from '../../../UIKit/components/Dialog/Dialog.component';
import { useDispatch, useSelector } from 'react-redux';
import { closeDialog } from '../../../../actions/dialogs.actions';
import { DialogType } from '../../../DialogRoot/DialogRoot.constants';
import messages from './WidgetSelectTableDialog.messages';
import theme from './WidgetSelectTableDialog.scss';
import { NodeId } from '@/serverapi/api';
import { TreeNode } from '@/models/tree.types';
import { TreeItemType } from '@/modules/Tree/models/tree';
import { Tree } from '@/modules/Tree/components/Tree/Tree.component';
import { setWidgetSource } from '@/actions/dashboard.actions';
import { TWidgetSource } from '../../Dashboard.types';
import { spreadsheetLoadById } from '@/actions/entities/spreadsheet.actions';
import { DashboardSelector } from '@/selectors/entities/dashboard.selectors';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import { treeItemsClearSelection } from '@/actions/tree.actions';
import { DialogFooterButtons } from '../../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';

type TWidgetSelectTableDialogProps = {
    nodeId: NodeId;
    widgetId?: string;
};

export const WidgetSelectTableDialog = (props: TWidgetSelectTableDialogProps) => {
    const { nodeId, widgetId } = props;
    const { repositoryId, serverId } = nodeId;

    const nodes: TreeNode[] = useSelector(DashboardSelector.getSpreadsheetTreeNodes(repositoryId, serverId));
    const lastClickedNode: TreeNode | undefined = useSelector(
        SelectedNodesSelector.getNode(DialogType.DASHBOARD_SELECT_TABLE_DIALOG),
    );

    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(closeDialog(DialogType.DASHBOARD_SELECT_TABLE_DIALOG));
        dispatch(treeItemsClearSelection());
    };
    const onSubmit = () => {
        if (nodeId && widgetId && lastClickedNode?.type === TreeItemType.Spreadsheet) {
            const source: TWidgetSource = {
                id: lastClickedNode.nodeId,
                name: lastClickedNode.name,
                dataset: [{ col1: '', col2: '', legend: '' }],
            };
            dispatch(setWidgetSource(nodeId, widgetId, source));
            dispatch(spreadsheetLoadById(source.id));
            dispatch(closeDialog(DialogType.DASHBOARD_SELECT_TABLE_DIALOG));
            dispatch(treeItemsClearSelection());
        }
    };

    const intl = useIntl();
    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: onClose,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: onSubmit,
                    value: intl.formatMessage(messages.apply),
                    visualStyle: 'primary',
                    disabled: lastClickedNode?.type !== TreeItemType.Spreadsheet,
                },
            ]}
        />
    );

    return (
        <Dialog
            width={612}
            open
            onOk={onSubmit}
            onCancel={onClose}
            title={intl.formatMessage(messages.title)}
            footer={footer}
            className={theme.dialog}
        >
            <div className={theme.treeContainer}>
                <Tree
                    includeFilterForSelect={[TreeItemType.Spreadsheet]}
                    data={nodes}
                    treeName={DialogType.DASHBOARD_SELECT_TABLE_DIALOG}
                    disableContextMenu
                />
            </div>
        </Dialog>
    );
};
