import { all, call, CallEffect, fork, put, select, takeEvery } from 'redux-saga/effects';
import { workspaceAddTab } from '../actions/tabs.actions';
import { EditorMode } from '../models/editorMode';
import { defaultWorkspaceTabActions } from '../models/tab';
import { TWorkspaceTabItemParams, TWorkspaceTab } from '../models/tab.types';
import { WorkSpaceTabTypes } from '../modules/Workspace/WorkSpaceTabTypesEnum';
import { TREE_ITEM_CONTEXT_MENU_ACTION } from '../actionsTypes/tree.actionTypes';
import { treeItemContextMenuAction } from '../actions/tree.actions';
import { ThandleOpenSearchPathDialodAction, TTreeItemContextMenuAction } from '../actions/tree.actions.types';
import { TreeItemContextMenuAction, TreeItemType } from '../modules/Tree/models/tree';
import {
    GET_SEARCH_RESULT,
    OPEN_MODEL_ON_CANVAS,
    OPEN_SEARCH_PATH_DIALOG,
    SET_SEARCH_PATH_ELEMENT,
} from '../actionsTypes/search.actionTypes';
import { setSearchData } from '../actions/search.actions';
import {
    TSetSearchPathElementAction,
    TGetSearchResultAction,
    TOpenModelOnCanvasAction,
} from '../actions/search.actions.types';
import { TServerEntity } from '../models/entities.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { TabsSelectors } from '../selectors/tabs.selectors';
import { openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { NodeId, PathResponse, SearchResponse } from '../serverapi/api';
import { SearchSelectors } from '../selectors/dbSearch.selector';
import { getCurrentLocale } from '../selectors/locale.selectors';
import { TSearchDataListItem } from '../reducers/search.reducer.types';
import { OPEN_BD_SEARCH_ACTION } from '../actionsTypes/editor.actionTypes';
import { LocalesService } from '../services/LocalesService';
import messages from '../modules/Tree/messages/TreeContextMenu.messages';
import { openNode } from '../actions/openNode.actions';
import { TreeDaoService } from '../services/dao/TreeDaoService';
import { generateCustomNodeId } from '../utils/nodeId.utils';
import { isTabSearchable } from '../utils/bdSearchTab.utils';
import { SearchDaoService } from '@/services/dao/SearchDAOService';
import { filterTreeIncludeTypes, TreeSelectors } from '@/selectors/tree.selectors';
import { TreeNode } from '@/models/tree.types';

type TGetSearchPathAction = {
    payload: {
        id: string;
        nodeIds: NodeId[];
    };
};

function* handleOpenSearchTab({ payload: { nodeId, name, action, type } }: TTreeItemContextMenuAction) {
    if (action === TreeItemContextMenuAction.DB_SEARCH) {
        const intl = LocalesService.useIntl(yield select(getCurrentLocale));
        const contentLoadingPageTab: TWorkspaceTab = {
            title: `${intl.formatMessage(messages.dbSearch)} «${name}»`,
            nodeId: generateCustomNodeId(nodeId, 'SearchTab'),
            type: WorkSpaceTabTypes.DB_SEARCH,
            mode: EditorMode.Read,
            params: {
                nodeId,
                name,
                type,
            } as TWorkspaceTabItemParams,
            actions: {
                ...defaultWorkspaceTabActions,
            },
        };
        yield fork(getSearchPath, { payload: { id: nodeId.id, nodeIds: [nodeId] } });
        yield put(setSearchData({ id: nodeId.id, nodeIds: [nodeId], name, type }));
        yield put(workspaceAddTab(contentLoadingPageTab));
    }
}

function* handleOpenBdSearchAction() {
    const tab: TWorkspaceTab | undefined = yield select(TabsSelectors.getActiveTab);
    const treeStructure: TreeNode[] = yield select(TreeSelectors.treeStructure);
    const treeStructureChildren: TreeNode[] = treeStructure[0]?.children || [];
    const onlyRepositories: TreeNode[] = filterTreeIncludeTypes(treeStructureChildren, [TreeItemType.Repository]);
    const isSingleRepository: boolean = onlyRepositories.length === 1;

    let nodeId: NodeId | undefined;

    if (isSingleRepository) {
        const singleRepositoryNodeId: NodeId | undefined = treeStructureChildren[0]?.nodeId;

        if (!singleRepositoryNodeId) return;

        nodeId = singleRepositoryNodeId;
    } else if (tab) {
        if (!isTabSearchable(tab)) return;

        nodeId = tab.nodeId;
    } else {
        return;
    }

    yield openDbSearchTab(nodeId);
}

function* openDbSearchTab(nodeId: NodeId) {
    const { serverId, repositoryId } = nodeId;
    const repositoryNodeId: NodeId = { id: repositoryId, repositoryId, serverId };
    const repositoryName: string = yield select(TreeSelectors.getNodeNameById(repositoryNodeId));

    yield put(
        treeItemContextMenuAction({
            nodeId: repositoryNodeId,
            name: repositoryName,
            action: TreeItemContextMenuAction.DB_SEARCH,
            type: TreeItemType.Repository,
        }),
    );
}

function* getSearchPath({ payload: { id, nodeIds } }: TGetSearchPathAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(nodeIds[0].serverId));

    const effects: CallEffect<PathResponse>[] = nodeIds.map((nodeId) =>
        call(TreeDaoService.getNodePath, nodeId.serverId, nodeId.id, nodeId.repositoryId),
    );

    const nodePaths: PathResponse[] = yield all(effects);
    const path: string[] = nodePaths.map((nodePath) => `${server.name}/${nodePath.path}`);

    yield put(setSearchData({ id, path }));
}

function* handleOpenSearchPathDialod({ payload: { searchUniqueId } }: ThandleOpenSearchPathDialodAction) {
    yield put(openDialog(DialogType.SEARCH_PATH, { searchUniqueId }));
}

function* handleSetSearchPathElement({ payload: { nodeIds } }: TSetSearchPathElementAction) {
    const id = yield select(SearchSelectors.getId);
    // При множественном поиске название вкладки не меняем
    // if (tab) {
    //     yield put(workspaceChangeTabTitle(tab, `${intl.formatMessage(messages.dbSearch)}`));
    // }

    yield fork(getSearchPath, { payload: { id, nodeIds } });
    yield put(setSearchData({ id, nodeIds }));
}

function* handleGetSearchResult({
    payload: { searchText, searchRules, searchVisibility, searchNodeTypes },
}: TGetSearchResultAction) {
    const serverId = yield select(SearchSelectors.getServerId);
    const id = yield select(SearchSelectors.getId);

    if (serverId) {
        const nodeIds: NodeId[] = yield select(SearchSelectors.getNodeIds);

        try {
            yield put(setSearchData({ id, isLoading: true }));

            const effects = nodeIds.map(function (nodeId) {
                return call(SearchDaoService.getExtendedSearchResponse.bind(SearchDaoService), {
                    rootSearchNodeId: nodeId,
                    searchText,
                    includePath: true,
                    includeCount: true,
                    searchVisibility,
                    searchRules: searchRules.map(({ attributeType, attributeTypeId, queryRule, values }) => ({
                        attributeType,
                        attributeTypeId,
                        queryRule,
                        values: values.map((val) => val.value),
                    })),
                    nodeTypes: searchNodeTypes,
                });
            });

            const nodePaths: SearchResponse[] = yield all(effects);
            const searchResult: TSearchDataListItem[] = nodePaths.flatMap((response: SearchResponse, index) =>
                response.resultList
                    ? response.resultList.map((item) => ({
                        multilingualName: item.multilingualName,
                        path: `${item.path}`,
                        type: item.nodeType as TreeItemType,
                        elementType: item.elementTypeId || '',
                        nodeId: {
                            ...item.nodeId,
                            serverId: nodeIds[index].serverId,
                        },
                        deleted: item.deleted,
                    }))
                    : []
            );

            const foundElementsCount: number = nodePaths.reduce(
                (acc, response) => acc + (response.foundElementsCount || 0),
                0,
            );

            yield put(setSearchData({ id, searchResult, searchText, foundElementsCount }));
        } finally {
            yield put(setSearchData({ id, isLoading: false }));
        }
    }
}

function* handleOpenModelOnCanvas({ payload: { nodeId, type, multilingualName } }: TOpenModelOnCanvasAction) {
    if (
        [
            TreeItemType.Model,
            TreeItemType.Matrix,
            TreeItemType.Wiki,
            TreeItemType.Spreadsheet,
            TreeItemType.Kanban,
            TreeItemType.SimulationModeling,
        ].includes(type)
    ) {
        yield put(openNode({ nodeId, type }));
    } else {
        if (type === TreeItemType.Folder) {
            yield put(openNode({ nodeId, type }));
        }

        yield put(
            treeItemContextMenuAction({
                nodeId,
                name: LocalesService.internationalStringToString(multilingualName),
                type,
                action: TreeItemContextMenuAction.PROPERTIES,
            }),
        );
    }
}

export function* searchSaga() {
    yield takeEvery(TREE_ITEM_CONTEXT_MENU_ACTION, handleOpenSearchTab);
    yield takeEvery(OPEN_SEARCH_PATH_DIALOG, handleOpenSearchPathDialod);
    yield takeEvery(SET_SEARCH_PATH_ELEMENT, handleSetSearchPathElement);
    yield takeEvery(GET_SEARCH_RESULT, handleGetSearchResult);
    yield takeEvery(OPEN_MODEL_ON_CANVAS, handleOpenModelOnCanvas);
    yield takeEvery(OPEN_BD_SEARCH_ACTION, handleOpenBdSearchAction);
}
