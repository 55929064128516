import type { TRootState } from '../reducers/root.reducer.types';
import type { NodeId } from '../serverapi/api';
import { createSelector } from 'reselect';
import { TreeItemType } from '../modules/Tree/models/tree';
import { compareNodeIds } from '@/utils/nodeId.utils';
import { NAVIGATOR_STRUCTURE } from '@/utils/consts';
import { TNavigatorStructureState, TSelectedNodesState } from '@/reducers/selectedNodes.reducer.types';
import { TreeNode } from '@/models/tree.types';

const getState = (state: TRootState) => state.selectedNodes;

export namespace SelectedNodesSelector {
    const getTreeNodes = (treeName: string = NAVIGATOR_STRUCTURE) =>
        createSelector<TRootState, TSelectedNodesState, TNavigatorStructureState>(getState, (state) => state[treeName]);

    export const getSelectedNodes = (treeName: string = NAVIGATOR_STRUCTURE) =>
        createSelector<TRootState, TNavigatorStructureState, TreeNode[]>(getTreeNodes(treeName), (treeNodes) => {
            return treeNodes.selectedNodes.filter(
                (node) =>
                    !(node.nodeId.id === node.nodeId.repositoryId && node.nodeId.id === node.nodeId.serverId)
            ) || [];
        });
        
    export const getNode = (treeName: string = NAVIGATOR_STRUCTURE) =>
        createSelector<TRootState, TSelectedNodesState, TreeNode | undefined>(
            getState,
            (state) => state[treeName]?.lastClickedNode,
        );

    export const getNodes = (treeName: string = NAVIGATOR_STRUCTURE) =>
        createSelector<TRootState, TSelectedNodesState, TreeNode[]>(
            getState,
            (state) => state[treeName]?.selectedNodes || [],
        );

    export const isNodeSelected = (nodeId?: NodeId, treeName: string = NAVIGATOR_STRUCTURE) =>
        createSelector<TRootState, TSelectedNodesState, boolean>(
            getState,
            (state) => !!state[treeName]?.selectedNodes.some((node) => compareNodeIds(node.nodeId, nodeId)),
        );

    export const moreThanOneSelected = (treeName: string = NAVIGATOR_STRUCTURE) =>
        createSelector<TRootState, TreeNode[], boolean>(
            getNodes(treeName),
            (selectedNodes) => selectedNodes?.length > 1,
        );

    export const twoModelsSelected = (treeName: string = NAVIGATOR_STRUCTURE) =>
        createSelector<TRootState, TreeNode[], boolean>(
            getNodes(treeName),
            (selectedNodes) =>
                selectedNodes?.length === 2 && !selectedNodes?.some((treeNode) => treeNode.type !== TreeItemType.Model),
        );
}
