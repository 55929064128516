import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { openSearchPathDialog } from '../../../actions/search.actions';
import iFolder from '../../../resources/icons/ic-tree-folder.svg';
import { SearchSelectors } from '../../../selectors/dbSearch.selector';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import style from './SearchPath.scss';
import messages from './SearchPath.messages';
import { NodeId } from '@/serverapi/api';

type TSearchPathProps = {
    intl: IntlShape;
    searchUniqueId: string;
    nodeId: NodeId;
};

const SearchPath = ({ intl, searchUniqueId, nodeId }: TSearchPathProps) => {
    const dispatch = useDispatch();
    const paths: string[] = useSelector(SearchSelectors.getPath) || [];

    const handleClick = () => {
        dispatch(openSearchPathDialog(searchUniqueId, nodeId));
    };

    return (
        <div className={style.searchPath}>
            <div className={style.icon} onClick={handleClick}>
                <Icon spriteSymbol={iFolder} />
                <h3>{`${intl.formatMessage(messages.header)}:`}</h3>
            </div>
            <div>
                {paths?.map((path) => {
                    return (
                        <div key={path} className={style.path}>
                            {path}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const IntlComponent = injectIntl(SearchPath);

export { IntlComponent as SearchPath };
