import React, { FC, useEffect, useState } from 'react';
import { Checkbox, Divider, Form, FormInstance, Input, Modal, Select } from 'antd';
import { ExtraButtonProperties, NodeId, ServerProfile } from '../../../serverapi/api';
import theme from './ExtraButtonSettings.scss';
import { TreeNode } from '../../../models/tree.types';
import messages from './ExtraButtonSettings.messages';
import { DEFAULT_DIALOG_WIDTH } from '../../../config/config';
import { useIntl } from 'react-intl';
import { TreeItemType } from '../../Tree/models/tree';
import { nodeService } from '../../../services/NodeService';
import { CustomMap } from '../../../utils/map';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentLocale } from '../../../selectors/locale.selectors';
import { TreeSelectors, getFileFolderTree } from '../../../selectors/tree.selectors';
import { Icon } from '../../UIKit/components/Icon/Icon.component';
import defaultImageIcon from '../../../resources/icons/ic-default-image.svg';
import { useExtraButtonCacheImages } from '../../../hooks/useExtraButtonCacheImages';
import { LocalesService } from '../../../services/LocalesService';
import { showDeleteConfirmation } from '../Button/DeleteButton';
import { shouldCallModalSubmitHandler } from '../../../services/utils/ModalHelper';
import { Tree } from '../../Tree/components/Tree/Tree.component';
import { ChangePositionButtons } from '../../ChangePositionButtons/ChangePositionButtons.component';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import { treeItemSelect } from '@/actions/tree.actions';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { DialogFooterButtons } from '../../UIKit/components/DialogFooterButtoms/DialogFooterButtons.component';
import { FormItemInputNumberWithLabel } from './components/FormItemInputNumberWithLabelComponent/FormItemInputNumberWithLabel.component';
import { BUTTONS_PANNEL_MAX_WIDTH, BUTTONS_PANNEL_MIN_WIDTH, SystemPropertiesFormItemNames } from './SystemProperties.types';
import { Rule } from 'rc-field-form/es/interface';

type TExtraButtonSettingsActionProps = {
    onSubmitButton: (button: ExtraButtonProperties) => void;
    onDeleteButton: (button: ExtraButtonProperties) => void;
    sortUp: (button: ExtraButtonProperties) => void;
    sortDown: (button: ExtraButtonProperties) => void;
};
const NAME_MAX_LENGTH = 100;

type TExtraButtonSettingsFullProps = TExtraButtonSettingsActionProps & {
    extraButtonProperties?: ExtraButtonProperties[];
    extraButtonsPanelWidth?: number;
    serverId: string;
    serverProfiles: Array<ServerProfile>;
};

export enum ExtraButtonType {
    LINK = 'LINK',
    MODEL = 'MODEL',
}

const modelsTree = 'ExtraButtonsSelectModel';
const imagesTree = 'ExtraButtonsSelectImage';


const getRule = (): Rule => {
    const intl = LocalesService.useIntl();

    return {
        type: 'number',
        min: BUTTONS_PANNEL_MIN_WIDTH,
        max: BUTTONS_PANNEL_MAX_WIDTH,
        message: intl.formatMessage(messages.widthRange, { min: BUTTONS_PANNEL_MIN_WIDTH, max: BUTTONS_PANNEL_MAX_WIDTH }),

    };
};

export const ExtraButtonSettings: FC<TExtraButtonSettingsFullProps> = ({
    extraButtonProperties,
    extraButtonsPanelWidth,
    onSubmitButton,
    onDeleteButton,
    sortUp,
    sortDown,
    serverProfiles,
    serverId,
}) => {
    const formRef = React.createRef<FormInstance>();
    const intl = useIntl();
    const dispatch = useDispatch();
    const currentLocale = useSelector(getCurrentLocale);
    const lastClickedModel: TreeNode | undefined = useSelector(SelectedNodesSelector.getNode(modelsTree));
    const lastClickedImage: TreeNode | undefined = useSelector(SelectedNodesSelector.getNode(imagesTree));

    const [extraButton, setExtraButton] = useState<ExtraButtonProperties>({});
    const [paths, setPaths] = useState<CustomMap<NodeId, string>>(new CustomMap());

    const [selectImageDialogVisible, setSelectImageDialogVisible] = useState<boolean>(false);
    const [selectModelDialogVisible, setSelectModelDialogVisible] = useState<boolean>(false);
    const [selectProfilesDialogVisible, setSelectProfilesDialogVisible] = useState<boolean>(false);
    const [createButtonDialogVisible, setEditButtonDialogVisible] = useState<boolean>(false);
    const [modelNodeNotValid, setModelNodeNotValid] = useState<boolean>(false);

    const [selectedProfiles, setSelectedProfiles] = useState<Array<string>>(extraButton.profilesIds || []);
    const [currentModelPath, setCurrentModelPath] = useState<string>('');

    const fileFolderTree = useSelector(getFileFolderTree);
    const treeNodes: TreeNode[] = useSelector(TreeSelectors.extraButtonsTreeNodes);

    const setSelectedModel = (node?: TreeNode) => dispatch(treeItemSelect(node, modelsTree));
    const setSelectedImage = (node?: TreeNode) => dispatch(treeItemSelect(node, imagesTree));

    useEffect(() => {
        const updatePaths = async () => {
            if (extraButtonProperties?.length) {
                const pathsToModels: CustomMap<NodeId, string> = paths.clone();
                const buttonsLinksToModels: Array<ExtraButtonProperties> = [
                    ...extraButtonProperties,
                    extraButton,
                ].filter((p) => p.type === ExtraButtonType.MODEL && p.nodeId && !pathsToModels.get(p.nodeId));

                await Promise.all(
                    buttonsLinksToModels.map(async (p) => {
                        const resp = await nodeService().loadPathFromNodeId({
                            ...p.nodeId!,
                            serverId,
                        });

                        if (resp && resp.path) {
                            pathsToModels.set(p.nodeId!, resp.path);
                        }
                    }),
                );

                setPaths(pathsToModels);
            }
        };

        updatePaths();
    }, [extraButtonProperties, extraButton]);

    useEffect(() => {
        if (!extraButton.nodeId) return;
        const path: string | undefined = paths.get(extraButton.nodeId);
        if (path) {
            setCurrentModelPath(path);
        }
    }, [extraButton, paths]);

    const { cacheFiles, setImagesIdList } = useExtraButtonCacheImages(extraButtonProperties || []);

    const handleSaveButtons = () => {
        const form = formRef.current;

        if (form) {
            form.validateFields().then(() => {
                if (extraButton.type === ExtraButtonType.MODEL && !extraButton?.nodeId) {
                    setModelNodeNotValid(true);
                } else {
                    if (extraButton) {
                        onSubmitButton(extraButton);
                    }
                    setEditButtonDialogVisible(false);
                }
            });
        }
    };

    const handleCancel = () => {
        setExtraButton({});
        setSelectedModel(undefined);
        setSelectedImage(undefined);
        setSelectedProfiles([]);
        setEditButtonDialogVisible(false);
    };

    const handleChangeType = (type) => {
        setExtraButton({
            ...extraButton,
            type,
        });
    };

    const handleChangeName = (e) => {
        const name = { ...extraButton.name };
        name[currentLocale] = e.target.value;
        setExtraButton({
            ...extraButton,
            name,
        });
    };

    const handleChangeURL = (e) => {
        const url = { ...extraButton.url };
        url[currentLocale] = e.target.value;
        setExtraButton({
            ...extraButton,
            url,
        });
    };

    const modelName =
        (extraButton.nodeId && paths.get(extraButton.nodeId)?.split('/')?.pop()) ||
        (lastClickedModel && lastClickedModel.name) ||
        '';

    const handleSelectModelSUbmit = () => {
        setExtraButton({
            ...extraButton,
            nodeId: lastClickedModel?.nodeId,
        });
        setModelNodeNotValid(false);
        setSelectModelDialogVisible(false);
    };

    const handleSelectImageSubmit = () => {
        if (lastClickedImage) {
            setImagesIdList((idList) => [...idList, lastClickedImage?.nodeId?.id!]);
            setExtraButton({
                ...extraButton,
                imageId: lastClickedImage?.nodeId?.id,
            });
        }
        setSelectImageDialogVisible(false);
    };

    const handleSelectProfileSubmit = () => {
        setExtraButton({
            ...extraButton,
            profilesIds: selectedProfiles,
        });
        setSelectProfilesDialogVisible(false);
    };

    const newButtonComponent = (
        <div
            onKeyUp={(e) => {
                if (shouldCallModalSubmitHandler(e.key)) {
                    handleSaveButtons();
                }
            }}
        >
            <Modal
                title={intl.formatMessage(messages.modalName)}
                open={createButtonDialogVisible}
                onOk={handleSaveButtons}
                onCancel={handleCancel}
                footer={
                    <DialogFooterButtons
                        buttons={[
                            {
                                key: 'cancel',
                                onClick: handleCancel,
                                value: intl.formatMessage(messages.formDeclineButton),
                            },
                            {
                                key: 'ok',
                                onClick: handleSaveButtons,
                                value: intl.formatMessage(messages.formConfirmButton),
                                visualStyle: 'primary',
                            },
                        ]}
                    />
                }
            >
                <Form ref={formRef}>
                    <Form.Item
                        name="name"
                        initialValue={LocalesService.internationalStringToString(extraButton.name, currentLocale)}
                        rules={[
                            {
                                required: false,
                                max: NAME_MAX_LENGTH,
                                message: intl.formatMessage(messages.nameErrorValidation),
                            },
                        ]}
                    >
                        <Input
                            onChange={handleChangeName}
                            value={LocalesService.internationalStringToString(extraButton.name, currentLocale)}
                            placeholder={intl.formatMessage(messages.extraBtnName)}
                        />
                    </Form.Item>
                    <Form.Item
                        name="type"
                        initialValue={extraButton?.type}
                        rules={[
                            {
                                required: true,
                                message: intl.formatMessage(messages.typeErrorValidation),
                            },
                        ]}
                    >
                        <Select
                            placeholder={intl.formatMessage(messages.extraBtnType)}
                            onChange={handleChangeType}
                            className={theme.sourceSelect}
                        >
                            {Object.keys(ExtraButtonType).map((s) => (
                                <Select.Option key={ExtraButtonType[s]} value={ExtraButtonType[s]}>
                                    {intl.formatMessage(messages[ExtraButtonType[s]])}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <div className={theme.buttonContainer}>
                        <Button
                            visualStyle="primary"
                            size="large"
                            onClick={() => {
                                setSelectImageDialogVisible(true);
                            }}
                        >
                            {intl.formatMessage(messages.extraBtnSelectImage)}
                        </Button>
                        {extraButton?.imageId ? (
                            <img className={theme.image} src={cacheFiles[extraButton.imageId]} />
                        ) : (
                            <Icon className={theme.image} spriteSymbol={defaultImageIcon} />
                        )}
                    </div>
                    <div className={theme.buttonContainer}>
                        <Button
                            visualStyle="primary"
                            size="large"
                            onClick={() => {
                                setSelectProfilesDialogVisible(true);
                                setSelectedProfiles(extraButton.profilesIds || []);
                            }}
                        >
                            {intl.formatMessage(messages.extraBtnSelectProfile)}
                        </Button>
                        {!!extraButton.profilesIds?.length && (
                            <div className={theme.profiles}>
                                <div>
                                    {extraButton.profilesIds
                                        ?.map((pid) => serverProfiles.find((sp) => sp.id === pid)?.name)
                                        .join(', ')}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className={theme.reservedSpaceForButton}>
                        {extraButton.type === ExtraButtonType.LINK ? (
                            <Form.Item
                                className={theme.buttonContainer}
                                name="url"
                                initialValue={LocalesService.internationalStringToString(
                                    extraButton.url,
                                    currentLocale,
                                )}
                                rules={[
                                    {
                                        required: true,
                                        message: intl.formatMessage(messages.urlErrorValidation),
                                    },
                                ]}
                            >
                                <Input
                                    onChange={handleChangeURL}
                                    placeholder={intl.formatMessage(messages.extraBtnLink)}
                                />
                            </Form.Item>
                        ) : extraButton.type === ExtraButtonType.MODEL ? (
                            <div className={theme.buttonContainer}>
                                <Button
                                    visualStyle="primary"
                                    danger={modelNodeNotValid}
                                    size="large"
                                    onClick={() => {
                                        setSelectModelDialogVisible(true);
                                    }}
                                >
                                    {intl.formatMessage(messages.extraBtnSelectModel)}
                                </Button>
                                {extraButton?.nodeId && (
                                    <div className={theme.selectedModel}>{currentModelPath || modelName}</div>
                                )}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </Form>
                {/* Селектор модели */}

                {selectModelDialogVisible && (
                    <div
                        onKeyUp={(e) => {
                            if (shouldCallModalSubmitHandler(e.key)) {
                                e.stopPropagation();
                                handleSelectModelSUbmit();
                            }
                        }}
                    >
                        <Modal
                            className={theme.modal}
                            onOk={handleSelectModelSUbmit}
                            onCancel={() => {
                                setSelectedModel(undefined);
                                setSelectModelDialogVisible(false);
                            }}
                            title={intl.formatMessage(messages.selectModelForm)}
                            open={selectModelDialogVisible}
                            width={DEFAULT_DIALOG_WIDTH}
                            footer={
                                <DialogFooterButtons
                                    buttons={[
                                        {
                                            key: 'cancel',
                                            onClick: () => {
                                                setSelectedModel(undefined);
                                                setSelectModelDialogVisible(false);
                                            },
                                            value: intl.formatMessage(messages.formDeclineButton),
                                        },
                                        {
                                            key: 'ok',
                                            onClick: handleSelectModelSUbmit,
                                            value: intl.formatMessage(messages.formConfirmButton),
                                            visualStyle: 'primary',
                                        },
                                    ]}
                                />
                            }
                        >
                            <Tree
                                data={treeNodes}
                                includeFilterForSelect={[TreeItemType.Model, TreeItemType.Wiki, TreeItemType.Matrix]}
                                treeName={modelsTree}
                                disableContextMenu
                            />
                        </Modal>
                    </div>
                )}

                {/* Селектор картинки */}
                {selectImageDialogVisible && (
                    <div
                        onKeyUp={(e) => {
                            if (shouldCallModalSubmitHandler(e.key)) {
                                e.stopPropagation();
                                handleSelectImageSubmit();
                            }
                        }}
                    >
                        <Modal
                            className={theme.modal}
                            onOk={handleSelectImageSubmit}
                            onCancel={() => {
                                setSelectedImage(undefined);
                                setSelectImageDialogVisible(false);
                            }}
                            title={useIntl().formatMessage(messages.selectImageForm)}
                            open={selectImageDialogVisible}
                            width={DEFAULT_DIALOG_WIDTH}
                            footer={
                                <DialogFooterButtons
                                    buttons={[
                                        {
                                            key: 'cancel',
                                            onClick: () => {
                                                setSelectedImage(undefined);
                                                setSelectImageDialogVisible(false);
                                            },
                                            value: intl.formatMessage(messages.formDeclineButton),
                                        },
                                        {
                                            key: 'ok',
                                            onClick: handleSelectImageSubmit,
                                            value: intl.formatMessage(messages.formConfirmButton),
                                            visualStyle: 'primary',
                                        },
                                    ]}
                                />
                            }
                        >
                            <Tree
                                includeFilterForSelect={[TreeItemType.File]}
                                treeName={imagesTree}
                                data={fileFolderTree}
                                disableContextMenu
                            />
                        </Modal>
                    </div>
                )}
                {/* Профили сервера */}

                {selectProfilesDialogVisible && (
                    <div
                        onKeyUp={(e) => {
                            if (shouldCallModalSubmitHandler(e.key)) {
                                e.stopPropagation();
                                handleSelectProfileSubmit();
                            }
                        }}
                    >
                        <Modal
                            className={theme.modal}
                            onOk={handleSelectProfileSubmit}
                            onCancel={() => {
                                setSelectedProfiles([]);
                                setSelectProfilesDialogVisible(false);
                            }}
                            title={intl.formatMessage(messages.selectProfilesForm)}
                            open={selectProfilesDialogVisible}
                            width={DEFAULT_DIALOG_WIDTH}
                            footer={
                                <DialogFooterButtons
                                    buttons={[
                                        {
                                            key: 'cancel',
                                            onClick: () => {
                                                setSelectedProfiles([]);
                                                setSelectProfilesDialogVisible(false);
                                            },
                                            value: intl.formatMessage(messages.formDeclineButton),
                                        },
                                        {
                                            key: 'ok',
                                            onClick: handleSelectProfileSubmit,
                                            value: intl.formatMessage(messages.formConfirmButton),
                                            visualStyle: 'primary',
                                        },
                                    ]}
                                />
                            }
                        >
                            <div className={theme.checkboxProfileContainer}>
                                {serverProfiles?.map((p) => (
                                    <div>
                                        <Checkbox
                                            key={p.id}
                                            checked={selectedProfiles.includes(p.id)}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setSelectedProfiles([...selectedProfiles, p.id]);
                                                } else {
                                                    setSelectedProfiles(selectedProfiles.filter((id) => id !== p.id));
                                                }
                                            }}
                                        >
                                            {p.name}
                                        </Checkbox>
                                    </div>
                                ))}
                            </div>
                        </Modal>
                    </div>
                )}
            </Modal>
        </div>
    );

    return (
        <div>
            <div className={theme.title}>{intl.formatMessage(messages.title)}</div>
            <div>
                {extraButtonProperties &&
                    extraButtonProperties
                        .filter((b) => b && b.id)
                        .map((b) => (
                            <div key={uuid()}>
                                <div className={theme.table}>
                                    <div className={theme.imageCol}>
                                        <div>
                                            {b.imageId ? (
                                                <img className={theme.image} src={cacheFiles[b.imageId]} />
                                            ) : (
                                                <Icon className={theme.image} spriteSymbol={defaultImageIcon} />
                                            )}
                                        </div>
                                    </div>
                                    <div className={theme.col}>
                                        <div>
                                            {b.type &&
                                                `${intl.formatMessage(messages.extraBtnType)}: ${intl.formatMessage(
                                                    messages[b.type!],
                                                )}`}
                                        </div>
                                        <div className={theme.path}>
                                            {(
                                                (b.type === ExtraButtonType.MODEL &&
                                                    b.nodeId &&
                                                    `${intl.formatMessage(messages.MODEL)}: ${
                                                        paths.get(b.nodeId) || ''
                                                    }`) ||
                                                (b.url &&
                                                    `${intl.formatMessage(
                                                        messages.LINK,
                                                    )}: ${LocalesService.internationalStringToString(
                                                        b.url,
                                                        currentLocale,
                                                    )}`)
                                            )?.slice(0, 150)}
                                        </div>
                                    </div>
                                    <div className={theme.col}>
                                        <div>
                                            {`${intl.formatMessage(
                                                messages.extraBtnName,
                                            )}: ${LocalesService.internationalStringToString(b.name, currentLocale)}`}
                                        </div>
                                        <div>
                                            {b.profilesIds &&
                                                intl.formatMessage(messages.allowedToProfiles) +
                                                    b.profilesIds
                                                        .map((id) => serverProfiles.find((p) => p.id === id)?.name)
                                                        ?.join(', ')}
                                        </div>
                                    </div>
                                </div>
                                <div className={theme.controlButtonsFooter}>
                                    <Button
                                        visualStyle="primary"
                                        onClick={() => {
                                            showDeleteConfirmation({
                                                onDelete: () => onDeleteButton(b),
                                                deleteQuestion: intl.formatMessage(messages.deleteModalTitle),
                                                dialogContent: intl
                                                    .formatMessage(messages.deleteModalText, {
                                                        name: LocalesService.internationalStringToString(
                                                            b.name,
                                                            currentLocale,
                                                        ),
                                                    })
                                                    .replace('""', ''),
                                            });
                                        }}
                                    >
                                        {intl.formatMessage(messages.deleteButton)}
                                    </Button>
                                    <Button
                                        visualStyle="primary"
                                        onClick={() => {
                                            setExtraButton(b);
                                            setEditButtonDialogVisible(true);
                                        }}
                                    >
                                        {intl.formatMessage(messages.editButton)}
                                    </Button>
                                    <span className={theme.positionButtonsContainer}>
                                        <ChangePositionButtons
                                            upButtonDisabled={
                                                extraButtonProperties.findIndex((btn) => b.id === btn.id) <= 0
                                            }
                                            downButtonDisabled={
                                                extraButtonProperties.findIndex((btn) => b.id === btn.id) >=
                                                extraButtonProperties.filter((btn) => btn?.id).length - 1
                                            }
                                            onUp={() => sortUp(b)}
                                            onDown={() => sortDown(b)}
                                        />
                                    </span>
                                </div>
                                <Divider />
                            </div>
                        ))}
            </div>
            <FormItemInputNumberWithLabel
                label={intl.formatMessage(messages.panelWidth)}
                initialValue={extraButtonsPanelWidth}
                name={SystemPropertiesFormItemNames.extraButtonPropertiesPanelWidth}
                placeholder={String(BUTTONS_PANNEL_MIN_WIDTH)}
                rules={[getRule()]}
            />
            {createButtonDialogVisible && newButtonComponent}
            <div className={theme.createButton}>
                <Button
                    dataTest="server-settings_extra-button_add-button"
                    onClick={() => {
                        setExtraButton({
                            id: uuid(),
                        });
                        setEditButtonDialogVisible(true);
                    }}
                >
                    {intl.formatMessage(messages.createButton)}
                </Button>
            </div>
        </div>
    );
};

export default ExtraButtonSettings;
