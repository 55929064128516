import type { TTreeItemSelectDialogProps } from './TreeItemSelectDialog.types';
import type { TRootState } from '../../../reducers/root.reducer.types';
import { connect } from 'react-redux';
import { closeDialog } from '../../../actions/dialogs.actions';
import { DialogType } from '../../DialogRoot/DialogRoot.constants';
import { TreeItemSelectDialog } from './TreeItemSelectDialog.component';
import { TreeItemType } from '../../Tree/models/tree';
import { TreeSelectors, filterTreeExcludeTypes, filterTreeIncludeTypes } from '../../../selectors/tree.selectors';
import { TreeNode } from '../../../models/tree.types';
import { SelectedNodesSelector } from '@/selectors/selectedNodes.selectors';
import { treeItemSelect, treeItemsClearSelection } from '@/actions/tree.actions';
import { NodeId } from '@/serverapi/api';

// скрипт можно запустить на любом узле, кроме админки
const NOT_ALLOW_NODE_TYPES = [TreeItemType.AdminTool];
const treeName = 'TreeItemSelect';

const mapStateToProps = (
    state: TRootState,
    ownProps: Partial<TTreeItemSelectDialogProps>,
): Partial<TTreeItemSelectDialogProps> => {
    const {
        serverId,
        repositoryId,
        excludeTypes = NOT_ALLOW_NODE_TYPES,
        includeTypes,
        typesAllowedToSelect,
    } = ownProps;
    const serverNode = TreeSelectors.treeStructure(state).find((node) => node.nodeId.serverId === serverId);
    const allRepos = serverNode?.children;
    const currentRepo = allRepos?.filter((node) => node.nodeId.repositoryId === repositoryId);
    const rawNodes = repositoryId ? currentRepo : allRepos;
    const filteredNodes = filterTreeExcludeTypes(rawNodes, excludeTypes);
    const treeLocation = includeTypes ? filterTreeIncludeTypes(filteredNodes, includeTypes) : filteredNodes;

    const lastClickedNode: TreeNode | undefined = SelectedNodesSelector.getNode(treeName)(state);

    return {
        treeLocation,
        typesAllowedToSelect,
        treeName,
        lastClickedNode,
    };
};

const mapDispatchToProps = (dispatch, ownProps: TTreeItemSelectDialogProps) => ({
    onClose: () => {
        if (ownProps.onClose) {
            ownProps.onClose();
        } else {
            dispatch(closeDialog(DialogType.TREE_ITEM_SELECT_DIALOG));
        }
        dispatch(treeItemsClearSelection());
    },
    onSubmit: (nodeId: NodeId, node: TreeNode) => {
        if (ownProps.onSubmit) {
            ownProps.onSubmit(nodeId, node);
        }
        dispatch(closeDialog(DialogType.TREE_ITEM_SELECT_DIALOG));
        dispatch(treeItemsClearSelection());
    },
    onClear: () => {
        dispatch(treeItemSelect(undefined, treeName));
        ownProps.onClear();
    },
});

export const TreeItemSelectDialogContainer = connect(mapStateToProps, mapDispatchToProps)(TreeItemSelectDialog);
